import * as React from "react"
import {Helmet} from "react-helmet";
import SupportForm from "../components/SupportForm";
import Layout from "../components/Layout";
import Section from "../components/Section";
import LogoLight from "images/logo-light.svg";
import Phone1 from "images/tr-section-1@2x.png";
import Phone2 from "images/tr-section-2@2x.png";
import Phone3 from "images/tr-section-3@2x.png";
import Phone4 from "images/tr-section-4@2x.png";
import Phone6 from "images/tr-section-6@2x.png";
import Phone5 from "../images/tr-section-5@2x.png";
import IconArrowed from "images/p-icon-arrowed.svg";
import IconCurrencies from "images/icon-currencies.svg";
import IconCurrencies2 from "images/icon-currencies-2.svg";
import IconPrivacy from "images/icon-privacy.svg";
import IconPhones from "images/icon-phones.svg";
import './index.scss';
import Badges from "../components/Badges";
import BgCircle from "../components/BgCircle";
import StickyBar from "components/StickyBar";
import LangBar from "components/LangBar";

const Homepage = () => {

  return <Layout lang={"tr"}>
    <Helmet>
      <meta charSet="utf-8"/>
      <title>Paramla</title>
      <script type="text/javascript" data-cmp-ab="1" src="https://cdn.consentmanager.net/delivery/autoblocking/0a22e461c211b.js" data-cmp-host="b.delivery.consentmanager.net" data-cmp-cdn="cdn.consentmanager.net" data-cmp-codesrc="0"></script>
    </Helmet>

    <LangBar />
    <StickyBar/>
    <BgCircle/>

    <Section id="1" bg={"dark-gradient"} image={Phone1}>
      <img className="section-text-image" alt="Paramla logo" src={LogoLight}/>
      <div className="section-text section-text--light">
        Tüm varlıklarının <br />
        değeri ve yüzdesel dağılımı <br/>
        tek ekranda, her an cebinde!

      </div>
      <div className="hide-on-mobile" style={{marginTop: 40}}><Badges/></div>
    </Section>

    <Section id="2" bg={"white"} imgLeft={true} image={Phone2}>
      <img className="section-text-image" alt="Paramla logo" src={IconArrowed}/>
      <div className="section-text section-text--dark">
        <div>
          Fiyatları ve '<span className={"text--ul"}>En Çok Kazandıranlar</span>'ıyla,<br/>
          8 kategoride toplam <span className={"text--ul"}>25 bin varlık</span><br/>
          arasından seçerek!
        </div>
        <div className="section-subtext">
          Türk Lirası | Hisse Senedi (BIST) | Emtia | Döviz<br/>
          Fon | Eurobond | Kripto Para | A.B.D. Borsaları
        </div>
      </div>
    </Section>

    <Section id="3" bg={"light"} image={Phone3}>
      <img className="section-text-image" alt="Paramla logo" src={IconCurrencies}/>
      <div className="section-text section-text--dark">
        <div>
          Dilediğin periyottaki <br/>
          değişim grafikleriyle!
        </div>
      </div>
    </Section>

    <Section id="4" bg={"white"} imgLeft={true} image={Phone4}>
      <img className="section-text-image" alt="Paramla logo" src={IconCurrencies2}/>
      <div className="section-text section-text--dark">
        <div>
          80 para biriminde, <br/>
          kar/zarar tutar & oranlarıyla!
        </div>
      </div>
    </Section>

    <Section id="5" bg={"light"} image={Phone5}>
      <img className="section-text-image" alt="Paramla logo" src={IconPhones}/>
      <div className="section-text section-text--dark">
        <div>
          10 cihaza kadar senkronize ederek, <br/>
          20 adede kadar portföy oluşturup <br/>
          <span className={"text--ul"}>paylaşarak</span> / <span className={"text--ul"}>birlikte yöneterek</span>!
        </div>
        <div className="section-subtext">
          Tek dokunuşla ister Türkçe, ister İngilizce!
        </div>
      </div>
    </Section>

    <Section id="6_yeni" bg={"dark"}>
      <img className="section-text-image" alt="Paramla logo" src={IconPrivacy}/>
      <div className="section-text section-text--light">
        <div>
          Üstelik,<br />
          kişisel veri paylaşmadan!
        </div>
        <div className="section-subtext">
          kayıt | giriş yok;<br/>
          <span className={"text--ul"}>her daim anonim kalarak</span>
        </div>
      </div>
    </Section>


    <Section id="6" bg={"white"} image={Phone6}>
      <Badges />
    </Section>
    <Section id="7" bg={"light"}>
      <SupportForm
          title1={{label: "Merak ettiğin,", isBold: false}}
          title2={{label: "söylemek istediğin", isBold: false}}
          title3={{label: "ne varsa:", isBold: false}}
          placeholderInput={"E-posta adresin"}
          placeholderText={"Mesajın"}
          successMessage1={"Mesajın için teşekkürler."}
          successMessage2={"En kısa sürede yanıt vereceğiz."}
      />
    </Section>
  </Layout>
}

export default Homepage;
